<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.w {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 30px;
  min-height: 704px;

  border-radius: 10px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.m_50 {
  height: 50px;
}
.title {
  padding-left: 27px;
  height: 98px;
  line-height: 98px;
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  border-bottom: 2px solid #f2f2f2;
}
.order {
  padding-left: 27px;
}
.num {
  height: 66px;
  line-height: 66px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  background-color: #fff;
}
.list {
  margin-right: 27px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
  .item {
    padding: 27px;
    background-color: #f2f2f2;
    justify-content: space-between;
    .item_left {
      margin-top: 27px;
      &:first-child {
        margin-top: 0;
      }
      .img {
        width: 200px;
        height: 121px;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 20px;
      }
      .name {
        line-height: 50px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .item-right {
      height: 121px;
      line-height: 121px;
      margin-right: 100px;
      font-size: 18px;
      font-weight: 500;
      color: #f13232;
    }
  }
}
.pay {
  margin: 0 27px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  .way {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    height: 66px;
    line-height: 66px;
  }
  .pay_img {
    cursor: pointer;
    width: 166px;
    height: 68px;
    background-color: antiquewhite;
  }
}
.bottom {
  padding-top: 25px;
  margin: 0 27px;
  font-weight: 500;
  color: #333333;
  .money_box {
    justify-content: flex-end;
    .money {
      margin-left: 40px;
      font-size: 20px;
      font-weight: 500;
      color: #f13232;
    }
  }
  .btns {
    padding-bottom: 60px;
    margin-top: 15px;
    justify-content: flex-end;
    div {
      cursor: pointer;
      text-align: center;
      margin-left: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #999999;
      width: 130px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #cccccc;
      border-radius: 20px;
      box-sizing: border-box;
    }
    .btn {
      color: #fff;
      border: 0;
      background: #f13232;
    }
  }
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 0;
  text-align: center;
  margin-top: 30vh;
}
// .ewm {
//   width: 50px;
//   height: 50px;
// }
</style>
<template>
  <div>
    <div class="w" v-if="flag">
      <div class="title">支付中心</div>
      <div class="order">
        <div class="num">订单编号：{{ orderInfo.orderid }}</div>

        <!-- <div class="list" v-if="orderInfo.type!=3">
          <div class="item flex" v-for="(item,index) in orderInfo.culum " :key="index">
            <div class="list_img">
              <div class="flex item_left" >
                <div class="img" >
                  <img :src="item.alias_litpic" />
                </div>
                <div class="name">{{item.alias_name}}</div>
              </div>
            </div>

            <div class="item-right">¥ {{item.alias_price}}</div>
          </div>
        </div>-->
        <div class="list" v-if="orderInfo.type != 3">
          <div class="item flex">
            <div class="list_img">
              <div class="flex item_left">
                <div class="img">
                  <img :src="orderInfo.culum.alias_litpic" />
                </div>
                <div class="name">{{ orderInfo.culum.alias_name }}</div>
              </div>
            </div>

            <div class="item-right">¥ {{ orderInfo.culum.alias_price }}</div>
          </div>
        </div>
        <div class="list" v-if="orderInfo.type == 3">
          <div class="item flex">
            <div class="list_img">
              <div class="flex item_left">
                <div class="name">{{ orderInfo.culum.name }}</div>
              </div>
            </div>

            <div class="item-right">¥ {{ orderInfo.culum.price }}</div>
          </div>
        </div>
      </div>
      <div class="pay">
        <div class="way">支付方式</div>
        <div class="pay_img">
          <img src="../../assets/img/course/pay.png" />
        </div>
      </div>
      <div class="bottom">
        <div class="money_box flex1">
          应付：
          <div class="money">¥ {{ orderInfo.profit }}</div>
        </div>

        <div class="btns flex1">
          <div @click="cancel()">取消</div>
          <div class="btn" @click="pay">立即支付</div>
        </div>
      </div>
    </div>
    <div class="m_50"></div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="220px"
      :before-close="handleClose"
    >
      <qriously class="ewm" :value="url" :size="200" />
    </el-dialog>
  </div>
</template>
<script>
import { orderInfo, alipay, payYes } from "../../assets/js/order";
export default {
  data() {
    return {
      imgUrl: "http://v2.hnzye.cn",
      siteId: window.localStorage.getItem("siteId"),
      user_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
      orderInfo: "",
      flag: false,
      dialogVisible: false,
      url: "",
      timer: "",
    };
  },
  mounted() {
    // console.log(this.$route.query.order_id);
    this.getInfo();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getInfo() {
      orderInfo({
        siteId: this.siteId,
        user_id: this.user_id,
        order_id: this.$route.query.order_id,
      }).then((res) => {
        // console.log(res.data);
        this.orderInfo = res.data.info;
        // console.log(this.orderInfo);
        this.flag = true;
      });
    },
    pay() {
      // window.open("https://www.cnblogs.com/xzybk/p/11759209.html",'_blank')
      alipay({
        id: this.$route.query.order_id,
        culum_id: this.orderInfo.culum_id,
        siteId: this.siteId,
      }).then((res) => {
        console.log(res);
        this.url = res.data.info.code_url;
        this.dialogVisible = true;
        // 走支付流程
        this.timer = setInterval(this.get, 1000);
      });
    },
    get() {
      // this.$message.success("支付成功！快去学习吧~");
      // this.dialogVisible = false;
      // this.$router.go(-1);
      // return;
      payYes({
        order_id: this.$route.query.order_id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("支付成功！快去学习吧~");
          this.dialogVisible = false;
          console.log("lklkl");
          if (res.data.type == 3) {
            this.$router.push({
              path: "/detail2",
              query: {
                id: res.data.culum_id,
              },
            });
          } else {
            this.$router.push({
              path: "/detail",
              query: {
                id: res.data.culum_id,
                active2: res.data.type,
              },
            });
          }
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    handleClose(done) {
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
  },
};
</script>